import React from "react";
import './NoAccess.scss';

export const NoAccess = () => {
    return (
        <div className="NoAccess__wrapper">
            <div className="noAccess__header">
                Access Restricted
            </div>
            <div className="noAccess__body">
                Unfortunately, you do not currently have permission to access this request. If you need access, please click below to request permission or contact your system administrator for further assistance
            </div>
            <div className="noAccess__footer">
                <button className="request--btn">Request Access</button>
                <button className="contactus--btn">Contact Support</button>
            </div>
        </div>
    )
}