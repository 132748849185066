import { CALLS } from 'redux/constants';

//  Communication Template actions
export const getCallsAPIData = (data, page) => ({
    type: CALLS.API_CALLS,
    payload: {
        page: page,
        data: data
    }
});

export const getCallsAPIDataPending = (page) => ({
    type: CALLS.API_CALLS_PENDING,
    payload: page
});

export const getCallsAPIDataSuccess = (payload) => ({
    type: CALLS.API_CALLS_SUCCESS,
    payload
});

export const getCallsAPIDataFailure = (error) => ({
    type: CALLS.API_CALLS_FAILURE,
    payload: { error: error?.toString() }
});
