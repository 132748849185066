import React, { useState } from "react";
import TargetIcon from 'assets/images/target.svg';
import PhoneIcon from "assets/images/phone-icon.svg";
import ClockIcon from "assets/images/clock-icon.svg";
import EyeIcon from "assets/images/eye-icon.svg";
import Image from "components/shared/Image";
import './UpcomingCalls.scss';

export const UpcomingCalls = () => {
    const callsData = [{
        name: 'Dianne Russel',
        designation: 'President of Sales - Amazon',
        vendorName: 'Network - Coleman',
        callDate: '12 April 2024',
        callTime: '3:00 PM IST',
        callType: 'Target'
    },
    {
        name: 'Dianne Russel',
        designation: 'President of Sales - Amazon',
        vendorName: 'Network - Coleman',
        callDate: '12 April 2024',
        callTime: '3:00 PM IST',
        callType: 'Target'
    }, {
        name: 'Dianne Russel',
        designation: 'President of Sales - Amazon',
        vendorName: 'Network - Coleman',
        callDate: '12 April 2024',
        callTime: '3:00 PM IST',
        callType: 'Target'
    },
    {
        name: 'Dianne Russel',
        designation: 'President of Sales - Amazon',
        vendorName: 'Network - Coleman',
        callDate: '12 April 2024',
        callTime: '3:00 PM IST',
        callType: 'Target'
    }, {
        name: 'Dianne Russel',
        designation: 'President of Sales - Amazon',
        vendorName: 'Network - Coleman',
        callDate: '12 April 2024',
        callTime: '3:00 PM IST',
        callType: 'Target'
    },
    {
        name: 'Dianne Russel',
        designation: 'President of Sales - Amazon',
        vendorName: 'Network - Coleman',
        callDate: '12 April 2024',
        callTime: '3:00 PM IST',
        callType: 'Target'
    },
    {
        name: 'Dianne Russel',
        designation: 'President of Sales - Amazon',
        vendorName: 'Network - Coleman',
        callDate: '12 April 2024',
        callTime: '3:00 PM IST',
        callType: 'Target'
    },
    {
        name: 'Dianne Russel',
        designation: 'President of Sales - Amazon',
        vendorName: 'Network - Coleman',
        callDate: '12 April 2024',
        callTime: '3:00 PM IST',
        callType: 'Target'
    }];
    const callsData2 = [];
    return (
        <div className="upcomingcalls__wrap">
            <div className="upcomingcalls__headwrap">
                <div className="upcomingcalls__headdata">
                    <div className="upcomingcalls--head">
                        Upcoming Calls
                    </div>
                    <div className="upcomingcalls--number">
                        {callsData?.length}
                    </div>
                </div>
                {
                    callsData?.length > 0 &&
                    <div className="upcomingcalls__headviewall">
                        <Image src={EyeIcon} alt="EyeIcon" className="eye--icon" />
                        View All
                    </div>
                }
            </div>
            <div className={callsData?.length === 0 ? "upcomingcalls__body upcomingcalls__body--nores background--image" : "upcomingcalls__body upcomingcalls__body--res"}>
                {
                    callsData?.length === 0
                        ?
                        <div className="nocalls__data">
                            <div className="nocalls--head">
                                No upcoming calls scheduled.
                            </div>
                            <div className="nocalls--text">
                                As you progress with your project and gather data, your analytics dashboard will populate here, offering insights into your activities.
                            </div>
                        </div>
                        : <div className="calls__datawrapper">
                            {
                                callsData?.map((data, index) =>
                                    <div className="calls__data" key={index}>
                                        <div className="calls__leftside">
                                            <Image src={PhoneIcon} alt="PhoneIcon" className="phone--icon" />
                                        </div>
                                        <div className="calls__rightside">
                                            <div className="calls__userwrap">
                                                <div className="calls__userinfo">
                                                    <div className="user--name">
                                                        {data?.name}
                                                    </div>
                                                    <div className="user--designation">
                                                        {data?.designation} | {data?.vendorName}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="call__date-type">
                                                <div className="call__dateclock">
                                                    <Image src={ClockIcon} alt="ClockIcon" />
                                                    <div className="call--date">
                                                        {data?.callDate} - {data?.callTime}
                                                    </div>
                                                </div>
                                                <div className="call--type">
                                                    <Image src={TargetIcon} alt="TargetIcon" />
                                                    {data?.callType}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                }
            </div>
        </div>
    )
}