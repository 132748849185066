import React, { useEffect, useState } from "react";
import Image from "components/shared/Image";
import LocationIcon from "assets/images/location-icon.svg";
import CostIcon from "assets/images/dollar.svg";
import DisputeIcon from 'assets/images/dispute-call.svg';
import CrossIcon from 'assets/images/red-cross.svg';
import PhoneIcon from 'assets/images/phone-icon.svg';
import PhoneGreenIcon from 'assets/images/phone-green.svg';
import LocationPin from 'assets/images/location-blue.svg';
import PhoneBlue from 'assets/images/phone-blue.svg';
import { TeamMembers } from "components/TeamMembers";
import { formatDate } from "utils/formatDate/formatDate";
import { convertTo12HourFormat, calculateTimeDifference, msToTime } from "utils/helpers/helpers";
import './CallsTemplate.scss';

export const CallsTemplate = ({ data }) => {
    const [callsData, setCallsData] = useState({});
    const [attendees, setAttendees] = useState([]);

    useEffect(() => {
        setAttendees([]);
    }, []);

    useEffect(() => {
        console.log('calls template data', data);
        setCallsData(data);
        let obj = [];
        data?.attendees?.map((item, index) => {
            obj?.push({ hrId: item?.attendeeHrId });
        })
        setAttendees(obj);
    }, [data]);
    

    const handleTimeDiff = (subItem) => {
        let hrsVar = 0;
        let minVar = 0;
        let resp = calculateTimeDifference(subItem?.startTime, subItem?.endTime);
        if (resp?.diffHours > 0) {
            hrsVar = `${resp?.diffHours} hr`
        }
        if (resp?.remainingMinutes > 0) {
            minVar = `${resp?.remainingMinutes} min`
        }
        const parts = [];
        if (hrsVar) parts.push(hrsVar);
        if (minVar) parts.push(minVar);
        return parts.join(' ');
    }

    return (
        <div className="callsTemplate--wrapper">
            {
                data?.status?.toLowerCase() === 'completed' ?
                    <div className="completedcalls--template">
                        <div className="completedcalls--header">
                            <div className="completedcalls--leftHead">
                                <div className="completedcalls--location">
                                    <Image src={LocationIcon} alt="LocationIcon" />
                                    Expert’s Country : {data?.expertProfile?.country}
                                </div>
                                <div className="completedcalls--cost">
                                    <Image src={CostIcon} alt="CostIcon" />
                                    15,260.00
                                </div>
                            </div>

                            <div className="completedcalls--rightHead">
                                <div className="completedcalls--disputecall">
                                    <Image src={DisputeIcon} alt="DisputeIcon" />
                                    Dispute Call
                                </div>
                                <div className="completedcalls--call">
                                    Follow Up Call
                                </div>
                            </div>
                        </div>

                        <div className="completedcalls--body">
                            <div className="attendees--head">Attendees</div>
                            <div className="attendees--data">
                                <TeamMembers data={attendees} templatePage={true} />
                            </div>
                        </div>
                        <div className="reschedule--call--secondarybtn">
                            <Image src={PhoneGreenIcon} alt="PhoneIcon" />
                            Schedule Another Call
                        </div>
                    </div>
                    :
                    <></>
            }
            {
                data?.status?.toLowerCase() === 'upcoming' &&
                <div className="completedcalls--template">
                    <div className="completedcalls--header">
                        <div className="completedcalls--leftHead">
                            <div className="completedcalls--location">
                                <Image src={LocationIcon} alt="LocationIcon" />
                                Expert’s Country : {data?.expertProfile?.country}
                            </div>
                        </div>
                    </div>

                    <div className="completedcalls--body">
                        <div className="attendees--head">Attendees</div>
                        <div className="attendees--data">
                            <TeamMembers data={attendees} templatePage={true} />
                        </div>

                        <div className="completedcalls--bodyFooter">
                            <div className="bodyFooter--leftSection">
                                <Image src={CrossIcon} alt="CrossIcon" />
                                Cancel Call
                            </div>
                            <div className="bodyFooter--rightSection">
                                <Image src={PhoneIcon} alt="PhoneIcon" />
                                Reschedule
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                data?.callStatus?.toLowerCase() === 'requested' &&
                <div className="completedcalls--template">
                    <div className="completedcalls--header">
                        <div className="completedcalls--leftHead">
                            <div className="completedcalls--location">
                                <Image src={LocationPin} alt="LocationPin" />
                                {data?.expertProfile?.country}
                            </div>
                            <div className="completedcalls--location">
                                <Image src={PhoneBlue} alt="PhoneBlue" />
                                TBD (Selected / Proposed Time Slots :&nbsp;
                                {
                                    data?.availableSlots?.map((subItem) =>
                                        `${formatDate(new Date(subItem?.
                                            startDateTime)?.toLocaleDateString())} | ${convertTo12HourFormat(msToTime(new Date(subItem?.startDateTime).getTime()))} - ${convertTo12HourFormat(msToTime(new Date(subItem?.endDateTime).getTime()))})`
                                    ).join(', ')
                                })
                            </div>
                        </div>
                    </div>
                    <div className="completedcalls--body">
                        <div className="attendees--head">Attendees</div>
                        <div className="attendees--data">
                            <TeamMembers data={attendees} templatePage={true} />
                        </div>
                    </div>
                </div>
            }
            {
                data?.status?.toLowerCase() === 'cancelled' &&
                <div className="completedcalls--template">
                    <div className="completedcalls--header">
                        <div className="completedcalls--leftHead">
                            <div className="completedcalls--location">
                                <Image src={LocationPin} alt="LocationPin" />
                                Expert’s Country : {data?.expertProfile?.country}
                            </div>
                        </div>
                    </div>
                    <div className="completedcalls--body">
                        <div className="attendees--head">Reason for Cancelation</div>
                        <div className="attendees--data">
                            Due to technical issues, we are unable to proceed with the call.
                        </div>

                        <div className="reschedule--call">
                            <Image src={PhoneIcon} alt="PhoneIcon" />
                            Schedule Another Call
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}