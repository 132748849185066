import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestDetailsDescription } from "components/RequestDetailsDescription";
import { AnalyticsCards } from "components/AnalyticsCards";
import { UpcomingCalls } from "components/UpcomingCalls";
import { ExternalNetworks } from "components/ExternalNetworks";
import { getEmployeeDesignation } from 'redux/actions/employeeDesignation/employeeDesignation.actions';
import { CompletedCalls } from "components/CompletedCalls";
import { TeamMembers } from "components/TeamMembers";
import './RequestDetails.scss';
import queryString from 'query-string';

export const RequestDetails = ({ reqData }) => {
    const dispatch = useDispatch();
    const queryData = queryString.parse(location.search)?.interviewId;

    useEffect(() => {
        // dispatch(getEmployeeDesignation(queryData));
    }, []);
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="requestDetails__wrap">
            <div className="requestDetails__leftsection">
                <RequestDetailsDescription reqData={reqData} />
                <AnalyticsCards />
                {/* <CompletedCalls /> */}
                <TeamMembers data={reqData?.caseTeamMembers} templatePage={false} />
            </div>
            <div className="requestDetails__rightsection">
                <UpcomingCalls />
                <ExternalNetworks extdata={reqData?.externalNetworks} />
            </div>
        </div>
    )
}