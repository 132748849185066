import React, { useState, useRef, useEffect } from "react";
import Modal from "components/shared/Modal";
import moment from 'moment';
import CloseIcon from 'assets/images/close-popup.svg';
import CrossIcon from 'assets/images/close-black.svg';
import PlusIcon from 'assets/images/plus-icon.svg';
import DownloadIcon from 'assets/images/download-icon.svg';
import DollarIcon from 'assets/images/dollar.svg';
import RedDollarIcon from 'assets/images/red-dollar.svg';
import RecommendedIcon from 'assets/images/compare-rec.svg';
import ThreeDotsIcon from 'assets/images/three-dots.svg';
import ClockIcon from "assets/images/clock-icon-green.svg";
import StarIcon from "assets/images/star-outline.svg";
import MinusIcon from "assets/images/minus-outline.svg";
import FilledStarIcon from "assets/images/star.svg";
import Image from "components/shared/Image";
import { Table } from 'semantic-ui-react';
import './CompareProfiles.scss';
import { useDispatch, useSelector } from "react-redux";
import { setExpertProfileActionsAPIData } from "redux/actions/expertProfileActions/expertProfileActions.actions";
import { selectors as subProfileActionSelector } from "redux/reducers/expertProfileActions/expertProfileActions_reducer";
import { toast } from "react-toastify";

export const CompareProfiles = ({ showPopup, popupType, selectedProfile, handleClosePopup, removeFromMainSelection, openScheduler }) => {
    const dispatchAction = useDispatch();
    const tableDetails = [
        { name: "Expert Name", type: 'field' },
        { name: "Cost", type: 'field' },
        { name: "External Network", type: 'field', column: "networkProvider" },
        { name: "Relevant Work Experience", type: 'title' },
        { name: "Relevant Employer", type: 'field', column: "relevantEmployer" },
        { name: "Title", type: 'field', column: "relevantTitle" },
        { name: "Tenure", type: 'field', column: "relevantTenure" },
        { name: "Current Employer", type: 'field', column: "currentEmployer" },
        { name: "Title", type: 'field', column: "currentTitle" },
        { name: "Tenure", type: 'field', column: "currentTenure" },
        { name: "Expert Types/Modules", type: 'field' },
        { name: "Expert Highlights/Biography", type: 'field', column: 'biography' },
        { name: "Country", type: 'field', column: 'country' },
        { name: "Screening Question & Answer", type: 'title' }
    ];

    const profileActionsArray = [
        { name: 'Request Call', type: 'call', icon: ClockIcon },
        { name: 'Shortlist Profile', type: 'shortlist', icon: StarIcon },
        { name: 'Shortlisted Profile', type: 'unshortlist', icon: FilledStarIcon },
        // {name: 'Request additional Info', icon: SlackIcon},
        { name: 'Decline Profile', type: 'decline', icon: MinusIcon }
    ];

    const popupRef = useRef(null);

    const [headers, setHeaders] = useState(tableDetails);
    const [profilesData, setProfilesData] = useState([]);
    const [openCompareModel, setOpenCompareModel] = useState(showPopup);
    const [hiddenCells, setHiddenCells] = useState([]);
    const [actionListOpen, setActionListOpen] = useState(-1);
    const [menuActionsMadeFlag, setmenuActionsMadeFlag] = useState(true);

    const getProfileActionStatus = useSelector(subProfileActionSelector.getSaveProfileActionsResults);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setActionListOpen(-1);
            }
        };

        const handleMouseDown = (event) => {
            // Check if the down arrow image was clicked
            // if (!event.target.classList.contains('arrow-img') && !event.target.classList.contains('dropdown--textbox')) {
            handleClickOutside(event);
        };
        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, []);

    useEffect(() => {
        if (getProfileActionStatus?.message) {
            !menuActionsMadeFlag ? toast.success(getProfileActionStatus.message) : '';
            setProfilesData(() => {
                const updatedData = [...profilesData];
                const recordIndex = updatedData.findIndex((item) => item.id == actionListOpen);
                if (recordIndex > -1) {
                    if (getProfileActionStatus.message?.indexOf('shortlist')) {
                        updatedData[recordIndex].shortlisted = true;
                    } else {
                        updatedData[recordIndex].profileStatus == "Declined";
                    }
                }
                return updatedData;
            });
            setmenuActionsMadeFlag(true);
        }
    }, [getProfileActionStatus]);

    useEffect(() => {
        if (selectedProfile?.length > 0) {
            let dataToSet = [...selectedProfile];
            dataToSet.forEach((profile) => {
                //extracting relevent experience details
                if (profile?.relevantExperience) {
                    const tenureAvailable = ((profile.relevantExperience.tenureYear != null && profile.relevantExperience.tenureYear > -1) && profile.relevantExperience.tenureMonth != null && profile.relevantExperience.tenureMonth > -1);
                    const startDate = moment().set('month', profile.relevantExperience?.startMonth - 1 || '')?.format('MMM') + ' ' + profile?.relevantExperience?.startYear || '',
                        endDate = profile.relevantExperience.employerStatus == "Current" ? 'Ongoing' : moment().set('month', profile.relevantExperience?.endMonth - 1 || '')?.format('MMM') + ' ' + profile?.relevantExperience?.endYear || '',
                        total = tenureAvailable ? profile.relevantExperience.tenureYear + ' yrs ' + profile.relevantExperience.tenureMonth + ' mo' : '';

                    profile.relevantEmployer = profile?.relevantExperience.employerName;
                    profile.relevantTitle = profile?.relevantExperience.title;
                    profile.relevantTenure = total ? startDate + " - " + endDate + " (" + total + ")" : startDate + " - " + endDate;
                }
                //extracting current experience details
                if (profile?.relevantExperience?.employerStatus == "Current") {
                    profile.currentEmployer = profile.relevantEmployer;
                    profile.currentTitle = profile.relevantTitle;
                    profile.currentTenure = profile.relevantTenure;
                } else {
                    profile.employmentHistory.forEach((emp) => {
                        if (emp.currentEmployment) {
                            const startDate = moment().set('month', emp.startMonth - 1 || '')?.format('MMM') + ' ' + emp.startYear || '';

                            profile.currentEmployer = emp.employerName;
                            profile.currentTitle = emp.title;
                            profile.currentTenure = startDate + " - Ongoing";
                        }
                    })
                }

                //formating screening questions
                if (profile.screeningQuestions?.length > 0) {
                    let moreHeaders = [];
                    profile.screeningQuestions.forEach((ques, index) => {
                        let quesCount = index + 1;
                        const headerExists = headers.filter((h) => h.name == "Question " + quesCount);
                        if (headerExists.length == 0) {
                            moreHeaders.push({ name: "Question " + quesCount, type: 'field', column: 'ques' + quesCount });
                            moreHeaders.push({ name: "Answer " + quesCount, type: 'field', column: 'ans' + quesCount });
                        }
                        profile['ques' + quesCount] = ques.question;
                        profile['ans' + quesCount] = ques.answer;
                    });
                    setHeaders(() => {
                        let updatedHeaders = [...headers];
                        updatedHeaders = updatedHeaders.concat(moreHeaders);
                        if (popupType == 'duplicate') {
                            updatedHeaders = [
                                ...updatedHeaders.slice(0, 2),
                                { name: "Status", type: 'field', column: 'profileStatus' },
                                ...updatedHeaders.slice(2, updatedHeaders.length)
                            ];
                        }
                        return updatedHeaders;
                    });
                }
            });
            setProfilesData(dataToSet);
        }
    }, [selectedProfile]);

    const handleRemoveFromPopup = (profileId) => {
        setProfilesData(() => {
            return profilesData.filter(item => item.profileId != profileId);
        });
        removeFromMainSelection(profileId);
    }

    const handleScroll = () => {
        const headerCells = document.querySelectorAll('.header-cell');
        const hiddenIndices = [];

        headerCells.forEach((headerCell, headerIndex) => {
            const headerRect = headerCell.getBoundingClientRect();
            const dataCells = document.querySelectorAll(`.data-cell:nth-child(${headerIndex + 1})`);

            dataCells.forEach((dataCell, itemIndex) => {
                const dataRect = dataCell.getBoundingClientRect();

                // Check if the dataCell is obscured by the headerCell
                if (
                    dataRect.left < headerRect.right &&
                    dataRect.right > headerRect.left
                ) {
                    hiddenIndices.push(itemIndex);
                }
            });
        });

        setHiddenCells(hiddenIndices);
    };

    const handleProfileActions = (type) => {
        if (type == 'call') {
            setOpenCompareModel(false);
            handleClosePopup();
            openScheduler(actionListOpen);
        } else {
            setActionListOpen(-1);
            setmenuActionsMadeFlag(false);
            dispatchAction(setExpertProfileActionsAPIData({ "ids": [actionListOpen], "action": type }));
        }
    };

    return (
        <Modal isOpen={openCompareModel} className="compare-profile-modal">
            <div className="header">
                <div className="compare__header">
                    <div className="compare-name"><span className="window-type">{popupType}</span> Expert Profiles</div>
                </div>
                <div className="header__right">
                    {popupType != 'duplicate' && <div className="add-experts" onClick={() => { setOpenCompareModel(false); handleClosePopup() }}> <Image src={PlusIcon} alt="PlusIcon" className="compare--details" />Add More Experts to Compare</div>}
                    <div className="add-experts download--border" onClick={() => { setOpenCompareModel(false); handleClosePopup() }}> <Image src={DownloadIcon} alt="DownloadIcon" className="compare--details" />Download Profiles</div>

                    <Image src={CloseIcon} alt="closeIcon" className="compare--details" onClick={() => { setOpenCompareModel(false); handleClosePopup() }} />
                </div>
            </div>
            <div className="table-container">
                <Table celled className='calls--table'>
                    <Table.Body className='completedcalls__body'>
                        {headers.map((header) => (
                            <Table.Row key={header.column}>
                                <Table.Cell className={header.type == 'title' ? 'heading-header-cell' : 'header-cell'}>{header.name}</Table.Cell>
                                {profilesData.map((item) => (
                                    <Table.Cell key={item.id} className={header.type == 'field' ? 'data-cell' : ''}
                                    >
                                        {header.name == "Expert Name" ?
                                            <div className="exp--name">
                                                <div className="exp--nameLeft">
                                                    {item?.expertName} {
                                                        item.recommended && <Image src={RecommendedIcon} className='dollar--icon' alt="RecommendedIcon" />}
                                                </div>
                                                <div className="exp--nameRight">
                                                    <div className='action-menu-div'>
                                                        {
                                                            actionListOpen == item.id &&
                                                            <div className="sort-popup" ref={popupRef}>
                                                                <ul>
                                                                    {profileActionsArray?.map((option, popupIndex) => (
                                                                        <>
                                                                            {option.type != 'unshortlist' && option.type != 'shortlist' &&
                                                                                <li key={popupIndex} className={item.profileStatus == "Declined" && option.type == 'decline' && 'disabled-action'} onClick={() => { handleProfileActions(option.type) }}>
                                                                                    <Image src={option.icon} className='edit--icon' alt="clock" />
                                                                                    {option.name}
                                                                                </li>
                                                                            }
                                                                            {item.shortlisted && option.type == 'unshortlist' &&
                                                                                <li key={popupIndex} onClick={() => { handleProfileActions(option.type) }}>
                                                                                    <Image src={option.icon} className='edit--icon' alt="clock" />
                                                                                    {option.name}
                                                                                </li>
                                                                            }
                                                                            {!item.shortlisted && option.type == 'shortlist' &&
                                                                                <li key={popupIndex} onClick={() => { handleProfileActions(option.type) }}>
                                                                                    <Image src={option.icon} className='edit--icon' alt="clock" />
                                                                                    {option.name}
                                                                                </li>
                                                                            }
                                                                        </>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        }
                                                    </div>
                                                    <Image src={ThreeDotsIcon} className='dollar--icon' alt="ThreeDotsIcon"
                                                        onClick={(event) => {
                                                            if (actionListOpen == item.id) {
                                                                setActionListOpen(-1);
                                                            } else {
                                                                setActionListOpen(item.id);
                                                            }
                                                        }} />
                                                    <Image src={CrossIcon} className='dollar--icon' alt="CloseIcon" onClick={() => { handleRemoveFromPopup(item.profileId) }} />
                                                </div>
                                            </div>
                                            : header.name == "Cost" ?
                                                <div className={item.rate.rateType.includes('Premium') ? "cost--container red--color" : "cost--container"}><Image src={item.rate.rateType.includes('Premium') ? RedDollarIcon : DollarIcon} className='dollar--icon' alt="DollarIcon" />{item.rate.costPerHour}/hr, {item.rate.costPer30Mins}/30 min ({item.rate.rateType})</div>
                                                : header.column ?
                                                    item[header.column]
                                                    : header.name == "Expert Types/Modules" ? (item?.expertTypes?.map((expItem, index) =>
                                                        <span key={expItem} className={index === 0 ? "expItem nomarginLeft" : "expItem"}>{expItem}</span>
                                                    ))
                                                        : null
                                        }
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        </Modal>
    );
}