import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Table, Dropdown } from 'semantic-ui-react';
import Image from "components/shared/Image";
import { CallsTemplate } from '../CallsTemplate';
import FilterIcon from "assets/images/filter_icon.svg";
import SearchIcon from "assets/images/search-icon.svg";
import CrossIcon from "assets/images/filter-close.svg";
import SortIcon from "assets/images/sort-icon.svg";
import SortAddedIcon from "assets/images/sort-applied.svg";
import ExpandIcon from "assets/images/calls-expand.svg";
import CollapseIcon from "assets/images/calls-collapse.svg";
import ClosedRequestsIcon from "assets/images/closed-requests.svg";
import WhiteCrossIcon from "assets/images/white-cross.svg";
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import DownloadIcon from "assets/images/download-icon.svg";
import { getCallsAPIData } from 'redux/actions/calls/calls.actions';
import { selectors as requestCallsSelectors } from 'redux/reducers/calls/calls_reducer';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroll-component';
import './RequestCalls.scss';

export const RequestCalls = ({ hideCallRec }) => {

    const callsData = [{
        id: 1,
        expertName: 'Leslie Alexander',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746'],
        status: 'Completed'
    },
    {
        id: 2,
        expertName: 'Dianne Russell',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746', '439256', '440527', '352900', '439744'],
        status: 'Upcoming'
    },
    {
        id: 3,
        expertName: 'Bessie Cooper',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: '-',
        atendees: ['440574', '439746', '439256', '440527'],
        status: 'Requested'
    },
    {
        id: 4,
        expertName: 'Floyd Miles',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746', '439256', '440527'],
        status: 'Cancelled'
    },
    {
        id: 5,
        expertName: 'Leslie Alexander',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746', '439256', '440527'],
        status: 'Completed'
    },
    {
        id: 6,
        expertName: 'Dianne Russell',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746', '439256', '440527'],
        status: 'Upcoming'
    },
    {
        id: 7,
        expertName: 'Bessie Cooper',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: '-',
        atendees: ['440574', '439256', '440527'],
        status: 'Requested'
    },
    {
        id: 8,
        expertName: 'Floyd Miles',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439256', '440527'],
        status: 'Cancelled'
    },
    {
        id: 9,
        expertName: 'Leslie Alexander',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439256', '440527'],
        status: 'Completed'
    },
    {
        id: 10,
        expertName: 'Dianne Russell',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746', '439256', '440527', '352900', '439744'],
        status: 'Upcoming'
    },
    {
        id: 11,
        expertName: 'Bessie Cooper',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: '-',
        atendees: ['440574', '439746', '439256', '440527', '352900', '439744'],
        status: 'Requested'
    },
    {
        id: 12,
        expertName: 'Floyd Miles',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746', '439256', '440527', '352900', '439744'],
        status: 'Cancelled'
    },
    {
        id: 13,
        expertName: 'Leslie Alexander',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['439746', '439256', '352901', '439745', '440576', '439747', '439255', '440528', '352904', '439748'],
        status: 'Completed'
    },
    {
        id: 14,
        expertName: 'Dianne Russell',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746', '439256', '440527', '352900'],
        status: 'Upcoming'
    },
    {
        id: 15,
        expertName: 'Bessie Cooper',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: '-',
        atendees: ['440574', '439746', '439256', '440527', '352900', '439744'],
        status: 'Requested'
    },
    {
        id: 16,
        expertName: 'Floyd Miles',
        relevantPos: 'Head of Product Design',
        company: 'Biffco Enterprises Ltd.',
        expertTypes: 'Company',
        extNetwork: 'AlphaSights',
        startDate: '22 Feb ‘2024',
        startTime: ' 9:15 AM - 10:15AM IST     (1 Hr)',
        atendees: ['440574', '439746'],
        status: 'Cancelled'
    }
    ]
    const dispatch = useDispatch();
    const callsSelectorData = useSelector(requestCallsSelectors?.getCallsData);
    const noRecFound = useSelector(requestCallsSelectors?.getNoRecordFound);
    const loading = useSelector(requestCallsSelectors?.getLoading);
    const [expandedData, setExpandedData] = useState(null);
    const [total, setTotal] = useState(0);
    const [expandedDataResp, setExpandedDataResp] = useState([]);
    const queryData = queryString.parse(location.search)?.interviewId;

    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([]);
    const [data, setData] = useState(callsData);
    const [lastPage, setLastPage] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [clickOutside, setClickOutside] = useState(false);
    const [showCrossIcon, setShowCrossIcon] = useState({
        'expertName': false,
        'company': false,
        'expertTypes': false,
        'extNetwork': false,
        'status': false,
        'startDate': false,
        'startTime': false
    });
    const dropdownRefs = {
        status: useRef(null),
        company: useRef(null),
        expertTypes: useRef(null),
        extNetwork: useRef(null),
        countries: useRef(null),
        expertName: useRef(null),
        startDate: useRef(null),
        startTime: useRef(null)
    };
    const [dropdownOpen, setDropdownOpen] = useState({
        status: false,
        company: false,
        expertTypes: false,
        extNetwork: false,
        countries: false,
        expertName: false,
        startDate: false,
        startTime: false
    });
    const [filters, setFilters] = useState({
        'expertName': [],
        'expertTypes': [],
        'relevantCompanies': [],
        'status': [],
        'networkProviders': [],
    });
    const [crossIconClick, setCrossIconClick] = useState(false);

    const [openScheduleCall, setOpenScheduleCall] = useState(false);
    const [openExpertProfileFlag, setOpenExpertProfile] = useState({
        open: false,
        selectedIndex: -1,
        selectedRecord: null,
        lastRecord: false
    });
    const [selectedExpert, setSelectedExpert] = useState();
    const isInitialMount = useRef(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setTotal(callsSelectorData?.totalCalls);
        let templateResp = callsSelectorData?.callDetails;
        if (isInitialMount.current) {
            // Skip the effect on initial mount
            isInitialMount.current = false;
            templateResp = [];
            return;
        }
        let newData = [...data] || [];

        if (!hideCallRec) {
            if (callsSelectorData?.lastPage && callsSelectorData?.firstPage) {
                setData(callsSelectorData.callDetails);
            }
            else if (!callsSelectorData?.lastPage) {
                if (data?.length > 0 && !callsSelectorData?.firstPage) {
                    for (let i = 0; i < templateResp?.length; i++) {
                        newData?.push(templateResp[i]);
                    }
                    setData([...newData]);
                } else {
                    setData(callsSelectorData.callDetails);
                }
            } else {
                for (let i = 0; i < templateResp?.length; i++) {
                    newData?.push(templateResp[i]);
                }
                setData([...newData]);
            }
            setLastPage(callsSelectorData?.lastPage);
            if (callsSelectorData?.callDetails?.length === 0) {
                setHasMore(false);
            }
        }
    }, [callsSelectorData]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            let isClickInside = false;
            for (const key in dropdownRefs) {
                if (dropdownRefs[key].current && dropdownRefs[key].current.contains(event.target)) {
                    isClickInside = true;
                    break;
                }
            }

            if (!isClickInside || event.target.classList.contains('filtercross--icon') || event.target.classList.contains('header--text') || event.target.classList.contains('header--divText')) {
                setClickOutside(true);
                // setExpertNameSearchTerm(filters['expertName']);
                setDropdownOpen({
                    status: false,
                    company: false,
                    expertTypes: false,
                    extNetwork: false,
                    countries: false,
                    expertName: false,
                    startDate: false,
                    startTime: false
                });
                setShowCrossIcon({
                    status: false,
                    company: false,
                    expertTypes: false,
                    extNetwork: false,
                    countries: false,
                    expertName: false,
                    startDate: false,
                    startTime: false
                });
                // setCompanySearchTerm('');
                // setCompaniesFilters(expRecFiltersData?.companies);
                // setCountriesFilters(expRecFiltersData?.countries);
                // setCountrySearchTerm('');
            }
            else {
                setClickOutside(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [crossIconClick]);

    const openCallScheduler = () => {
        setOpenScheduleCall(true);
    }
    const handleRowClick = (item) => {
        setExpandedDataResp(item);
        setExpandedData((prevId) => (prevId === item?.id ? null : item?.id));
    }

    const handleStatus = (item) => {
        if (item === 'Completed') {
            return 'completed--status'
        }
        else if (item === 'Upcoming') {
            return 'upcoming--status'
        }
        else if (item === 'Requested') {
            return 'requested--status'
        }
        else if (item === 'Cancelled') {
            return 'cancelled--status'
        }
    }

    const handleCrossIconClick = (type) => {
        setDropdownOpen({
            status: false,
            company: false,
            expertTypes: false,
            extNetwork: false,
            countries: false,
            expertName: false,
            startDate: false,
            startTime: false
        });
        setCrossIconClick(true);
        //  setExpertNameSearchTerm(filters['expertName']);
        toggleCrossIcon(type, false);
        // setCompanySearchTerm('');
        // setCountrySearchTerm('');
        // setCompaniesFilters(expRecFiltersData?.companies);
        // setCountriesFilters(expRecFiltersData?.countries);
    };

    const openExpertProfile = (record, index) => {
        setOpenExpertProfile({
            open: true,
            selectedIndex: index,
            selectedRecord: record,
            lastRecord: (index + 1) == data.length
        });
    }
    const handleSchedulePopupClose = () => {
        setOpenScheduleCall(false);
    }
    const handleExpertProfileClose = () => {
        setOpenExpertProfile({
            open: false,
            selectedIndex: -1,
            selectedRecord: null,
            lastRecord: false
        });
    }

    const loadMoreData = () => {
        if (lastPage === false) {
            setPage(prevPage => prevPage + 1);
            fetchData(page + 1);
        }
    };

    useEffect(() => {
        fetchData(page);
    }, [hideCallRec]);

    const fetchData = async (page) => {
        if (!hideCallRec) {
            let templateQueryData = {
                requestId: queryData,
                expertName: filters['expertName']?.length > 0 ? filters['expertName'] : null,
                expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
                relevantCompanies: filters['relevantCompanies']?.length > 0 ? filters['relevantCompanies'] : null,
                status: filters['status']?.length > 0 ? filters['status'] : null,
                networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
            }
            dispatch(getCallsAPIData(templateQueryData, page));
        }
    };

    const handleSort = (event) => {
        console.log('event in handleSort', event);
    }


    const handleCheckBoxChange = (event, inputData) => {
        let prevdata = selectedCheckBoxes; // Initialize prevdata
        let updatedSelectedCheckBoxes;
        if (inputData.checked) {
            updatedSelectedCheckBoxes = [...selectedCheckBoxes, inputData?.label?.templateId]
            setSelectedCheckBoxes(prev => [...prev, inputData?.label?.templateId]);
        } else {
            updatedSelectedCheckBoxes = selectedCheckBoxes.filter(item => item !== inputData?.label?.templateId);
            setSelectedCheckBoxes(prev => prev.filter(item => item !== inputData?.label?.templateId));
        }
    }
    const handleClearFilter = (column) => {
        setFilters({ ...filters, [column]: [] });
        toggleCrossIcon(column, false);
    };

    const toggleCrossIcon = (column, flag) => {
        if (flag) {
            setShowCrossIcon({ ...showCrossIcon, [column]: flag });
        }
        else {
            setShowCrossIcon({ ...showCrossIcon, [column]: false });
        }
    };

    const handleAllCheckChange = () => {
        let temp = [];
        // let tempData = data?.filter(((itemTemp) => itemTemp?.status === 'Awaiting Approval'));
        let tempData = data;
        if (selectedCheckBoxes?.length === tempData?.length) {
            setSelectedCheckBoxes([]);
        }
        else {
            data?.map((item, index) => {
                // if (item?.status === 'Awaiting Approval')

                temp?.push(item?.templateId);
            });
        }

        setSelectedCheckBoxes(temp);
    }

    const handleCarouselActionClicked = (action, selectionIndex) => {
        if (action == 'next') {
            onExpertNameClicked(selectionIndex + 1);
            setOpenExpertProfile({
                open: true,
                selectedIndex: selectionIndex + 1,
                selectedRecord: data[selectionIndex + 1],
                lastRecord: (selectionIndex + 2) == data.length
            });
        } else {
            if (selectionIndex > 0) {
                onExpertNameClicked(selectionIndex - 1);
                setOpenExpertProfile({
                    open: true,
                    selectedIndex: selectionIndex - 1,
                    selectedRecord: data[selectionIndex - 1],
                    lastRecord: (selectionIndex + 2) == data.length
                });
            }
        }
    }

    const handlerRequestCallClick = () => {
        openCallScheduler();
    }

    const onExpertNameClicked = (selectedIndex) => {
        let selectedData = {};
        if (selectedIndex == 1) {
            selectedData = {
                name: 'Oliver Sweet',
                position: 'Senior Procurement',
                company: 'Walmart',
                location: 'Germany',
                type: 'Supplier',
                extNetwork: 'Athenum',
                status: "Approved",
                slotDetails: [{
                    checked: true,
                    date: '20/02/2024',
                    day: 'Tuesday',
                    time: '2:00 - 2:30 PM',
                    timezone: 'CST'
                }]
            };
        } else {
            selectedData = {
                name: 'Hanz Weber',
                position: 'Senior Procurement',
                company: 'Walmart',
                location: 'Italy',
                type: 'Target',
                extNetwork: 'Guidepoint',
                status: "Approved",
                slotDetails: [{
                    checked: true,
                    date: '20/02/2024',
                    day: 'Tuesday',
                    time: '2:00 - 2:30 PM',
                    timezone: 'CST'
                }]
            };
        }
        setSelectedExpert(selectedData);
    }

    return (
        <>
            <div className="requestCalls__wrap">
                <div className='requestCalls__head'>
                    <div className="grid-header-count">
                        <div className='count--badge'>{total || '0'}</div>
                        Calls
                    </div>
                    <div className='download__callWrap'>
                        <Image src={DownloadIcon} className='download--icon' alt="DownloadIcon"
                        // onClick={() => exportToExcel()} 
                        />
                        Download Call Data
                    </div>
                </div>
                {
                    noRecFound === false &&
                    <div className="requestCalls__top-filters">
                        <div className="top-filter-name">Follow Up Calls &nbsp;2</div>
                    </div>
                }
                {
                    noRecFound === false ?
                        <>
                            <Table sortable celled className='calls--table'>
                                <Table.Header className='completedcalls__header'>
                                    <Table.Row>
                                        <Table.HeaderCell className='header--title width-action-col'>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className='header--title'>
                                            <div className='header--div' ref={dropdownRefs['expertName']}>
                                                <div className='header--divText'>
                                                    <span className='header--text'>
                                                        Expert Name
                                                    </span>
                                                </div>
                                                <Dropdown open={dropdownOpen['expertName']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                status: false,
                                                                company: false,
                                                                expertTypes: false,
                                                                extNetwork: false,
                                                                countries: false,
                                                                expertName: true,
                                                                startDate: false,
                                                                startTime: false
                                                            });
                                                            setShowCrossIcon({
                                                                status: false,
                                                                company: false,
                                                                expertTypes: false,
                                                                extNetwork: false,
                                                                countries: false,
                                                                expertName: true,
                                                                startDate: false,
                                                                startTime: false
                                                            });
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['expertName'] === true ? CrossIcon : SearchIcon} alt="icon" className={dropdownOpen['expertName'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('expertName');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon">

                                                </Dropdown>
                                            </div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className='header--title'>
                                            <div className='header--div'>
                                                <div className='header--divText'>
                                                    <span className='header--text'>
                                                        Relevant Position
                                                    </span>
                                                </div>
                                            </div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className='header--title'>
                                            <div className='header--div' ref={dropdownRefs['company']}>
                                                <div className='header--divText'>
                                                    <span className='header--text'>
                                                        Relevant Company
                                                    </span>
                                                    {/* {
                                                filters?.type?.length > 0 &&
                                                <span className='filter--count'>{filters?.type?.length}<Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('type')} /></span>
                                            } */}
                                                </div>
                                                <Dropdown open={dropdownOpen['company']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                status: false,
                                                                company: true,
                                                                expertTypes: false,
                                                                extNetwork: false,
                                                                countries: false,
                                                                expertName: false,
                                                                startDate: false,
                                                                startTime: false
                                                            });
                                                            setShowCrossIcon({
                                                                status: false,
                                                                company: true,
                                                                expertTypes: false,
                                                                extNetwork: false,
                                                                countries: false,
                                                                expertName: false,
                                                                startDate: false,
                                                                startTime: false
                                                            });
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['company'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['company'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('company');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon">

                                                </Dropdown>
                                            </div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className='header--title'>
                                            <div className='header--div' ref={dropdownRefs['expertTypes']}>
                                                <div className='header--divText'>
                                                    <span className='header--text'>
                                                        Expert Type / Modules
                                                    </span>
                                                    {/* {
                                                        filters?.type?.length > 0 &&
                                                        <span className='filter--count'>{filters?.type?.length}<Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('type')} /></span>
                                                    } */}
                                                </div>
                                                <Dropdown open={dropdownOpen['expertTypes']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                status: false,
                                                                company: false,
                                                                expertTypes: true,
                                                                extNetwork: false,
                                                                countries: false,
                                                                expertName: false,
                                                                startDate: false,
                                                                startTime: false
                                                            });
                                                            setShowCrossIcon({
                                                                status: false,
                                                                company: false,
                                                                expertTypes: true,
                                                                extNetwork: false,
                                                                countries: false,
                                                                expertName: false,
                                                                startDate: false,
                                                                startTime: false
                                                            });
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['expertTypes'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['expertTypes'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('expertTypes');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon">

                                                </Dropdown>
                                            </div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className='header--title'>
                                            <div className='header--div' ref={dropdownRefs['extNetwork']}>
                                                <div className='header--divText'>
                                                    <span className='header--text'>
                                                        Network
                                                    </span>
                                                </div>
                                                {/* {
                                                    filters?.status?.length > 0 &&
                                                    <span className='filter--count statuscount'>{filters?.status?.length}<Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('status')} /></span>
                                                } */}
                                                <Dropdown open={dropdownOpen['extNetwork']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                status: false,
                                                                company: false,
                                                                expertTypes: false,
                                                                extNetwork: true,
                                                                countries: false,
                                                                expertName: false,
                                                                startDate: false,
                                                                startTime: false
                                                            });
                                                            setShowCrossIcon({
                                                                status: false,
                                                                company: false,
                                                                expertTypes: false,
                                                                extNetwork: true,
                                                                countries: false,
                                                                expertName: false,
                                                                startDate: false,
                                                                startTime: false
                                                            });
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['extNetwork'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['extNetwork'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('extNetwork');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon">

                                                </Dropdown>
                                            </div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className='header--title'>
                                            <div className='header--div' ref={dropdownRefs['startDate']}>
                                                <div className='header--divText'>
                                                    <span className='header--text'>
                                                        Date
                                                    </span>
                                                </div>
                                                <Image src={SortIcon} alt="icon" className={dropdownOpen['startDate'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                // onClick={(event) => {
                                                //     event.stopPropagation();
                                                //     handleCrossIconClick('startDate');
                                                // }
                                                // }
                                                />
                                                {/* <Dropdown open={dropdownOpen['startDate']}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (!clickOutside) {
                                                    setDropdownOpen({
                                                        status: false,
                                                        company: false,
                                                        expertTypes: false,
                                                        extNetwork: false,
                                                        countries: false,
                                                        expertName: false,
                                                        startDate: true,
                                                        startTime: false
                                                    });
                                                    setShowCrossIcon({
                                                        status: false,
                                                        company: false,
                                                        expertTypes: false,
                                                        extNetwork: false,
                                                        countries: false,
                                                        expertName: false,
                                                        startDate: true,
                                                        startTime: false
                                                    });
                                                }
                                                else {
                                                    setClickOutside(false);
                                                }
                                            }}
                                            closeOnChange={false}
                                            icon={<Image src={showCrossIcon['startDate'] === true ? SortAddedIcon : SortIcon} alt="icon" className={dropdownOpen['startDate'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleCrossIconClick('startDate');
                                                }
                                                }
                                            />}
                                            floating
                                            labeled
                                            button
                                            className="icon">

                                        </Dropdown> */}
                                            </div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className='header--title'>
                                            <div className='header--div' ref={dropdownRefs['startTime']}>
                                                <div className='header--divText'>
                                                    <span className='header--text'>
                                                        Start Time
                                                    </span>
                                                </div>
                                            </div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className='header--title'>
                                            <div className='header--div' ref={dropdownRefs['status']}>
                                                <div className='header--divText'>
                                                    <span className='header--text'>
                                                        Status
                                                    </span>
                                                </div>
                                                <Dropdown open={dropdownOpen['status']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                status: true,
                                                                company: false,
                                                                expertTypes: false,
                                                                extNetwork: false,
                                                                countries: false,
                                                                expertName: false,
                                                                startDate: false,
                                                                startTime: false
                                                            });
                                                            setShowCrossIcon({
                                                                status: true,
                                                                company: false,
                                                                expertTypes: false,
                                                                extNetwork: false,
                                                                countries: false,
                                                                expertName: false,
                                                                startDate: false,
                                                                startTime: false
                                                            });
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['status'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['status'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('status');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon">

                                                </Dropdown>
                                            </div>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                            </Table>
                            <div id="scrollable-container">
                                <InfiniteScroll
                                    dataLength={data?.length || 0}
                                    hasMore={hasMore}
                                    scrollableTarget="scrollable-container"
                                    next={loadMoreData}
                                    className='commTemplates__infiniteScroll'
                                >
                                    <Table sortable celled className='calls--table'>
                                        <Table.Body className='completedcalls__body'>
                                            {
                                                (loading === true && noRecFound === false) ?
                                                    <div className='loading--center'>
                                                        <ImageParaSkeleton showPara={true} paraCount={5} size="fullWidth" height={4} />
                                                    </div>
                                                    :
                                                    <>
                                                        {!data || data?.length == 0 ?
                                                            <div className='noRecFound'>
                                                                Currently, there are no calls scheduled yet.
                                                            </div>
                                                            :
                                                            data?.map((item, index) => (
                                                                <><>
                                                                    <Table.Row key={index} className={item?.profileStatus?.toLowerCase() === 'new' && !item?.shortlisted ? "exp--row rowShortlistedClr" : "exp--row rowDefaultClr"} onClick={() => handleRowClick(item)}>
                                                                        <Table.Cell className='user--data table--cell expand--data'><Image src={expandedData === (item?.id) ? CollapseIcon : ExpandIcon} akt="ExpandIcon" /></Table.Cell>
                                                                        <Table.Cell className='user--data table--cell expert--name'>{item?.expertProfile?.expertName}</Table.Cell>
                                                                        <Table.Cell className='user--data table--cell'>{item?.expertProfile?.relevantExperience?.title}</Table.Cell>
                                                                        <Table.Cell className='user--data table--cell'>{item?.expertProfile?.relevantExperience?.employerName}</Table.Cell>
                                                                        <Table.Cell className='user--data table--cell'>{Array.isArray(item?.expertProfile?.expertTypes) ? item?.expertProfile?.expertTypes.join(', ') : item?.expertProfile?.expertTypes}</Table.Cell>
                                                                        <Table.Cell className='user--data table--cell'>{item?.expertProfile?.networkProvider}</Table.Cell>
                                                                        <Table.Cell className='user--data table--cell'>{item?.callStatus === 'Requested' ? 'NA' : item?.expertProfile?.startDate}</Table.Cell>
                                                                        <Table.Cell className='user--data table--cell'>{item?.callStatus === 'Requested' ? 'NA' : item?.expertProfile?.startTime}</Table.Cell>
                                                                        <Table.Cell className='status--cell'><div className={handleStatus(item?.callStatus)}>{item?.callStatus}</div></Table.Cell>
                                                                    </Table.Row>
                                                                </><>
                                                                        {expandedData === (item?.id) && (
                                                                            <Table.Row className='template--div'>
                                                                                <Table.Cell colSpan={9} className='template--cellwrap'>
                                                                                    <div className='template--cell'>
                                                                                        <CallsTemplate
                                                                                            data={expandedDataResp} />
                                                                                    </div>
                                                                                </Table.Cell>
                                                                            </Table.Row>)}
                                                                    </></>
                                                            ))
                                                        }
                                                    </>
                                            }
                                        </Table.Body>
                                    </Table>
                                </InfiniteScroll>

                            </div>
                        </>
                        :
                        <>
                            {
                                noRecFound === true &&
                                <div className="templates__wrap">
                                    <div className="templates--title">No Calls Scheduled Yet</div>
                                    <div className="templates--subtitle">Your request is active, but no calls have been scheduled with experts yet. You can initiate scheduling by clicking &apos;Request Call&apos; on your selected expert&apos;s profiles. Check back here for managing upcoming calls.</div>

                                    <Image src={ClosedRequestsIcon} alt="ClosedRequestsIcon" className="closedreq--icon" />
                                </div>
                            }
                        </>}
            </div>
        </>
    );
};