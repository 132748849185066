import React, { useEffect, useRef, useState } from "react";
import "./ScheduleCall.scss";
import Modal from "components/shared/Modal";
import moment from 'moment-timezone';
import { DropdownField } from "components/shared/DropdownField";
import { Checkbox, Image } from "semantic-ui-react";
import { changeTimeFormat, msToTime } from "utils/helpers/helpers";
import CalIcon from "assets/images/blue_calender_icon.svg";
import ClockIcon from "assets/images/clock-icon.svg";
import InputClockIcon from "assets/images/clock-icon-green.svg";
import '@bcg-web/bcg-people-search';
// import '@kdshared/peopleselector';
import CONFIG from "config";
import authService from 'redux/auth';
import { getUserProfilePictures } from "api/getUserProfilePictures";
import CloseIcon from 'assets/images/close-chargeCode.svg';
import PlusIcon from 'assets/images/plus-large.svg';
import { DatePickerField } from "components/DatePickerField";
import CrossIcon from "assets/images/cross-large.svg";
import { setSaveCallSlotsAPIData } from "redux/actions/saveCallSlots/saveCallSlots.actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const ScheduleCall = ({ open, data, caseTeamMembers, requestId, closePopupHandler }) => {
    const dispatch = useDispatch();
    const invitesFieldRef = useRef();

    const durationList = [
        { name: '15 mins', value: 15 },
        { name: '30 mins', value: 30 },
        { name: '60 mins', value: 60 },
        { name: '90 mins', value: 90 }
    ];

    const [openModel, setOpenModel] = useState(open);
    const [accessToken, setAccessToken] = useState([]);
    const [expertSectionOpen, setExpertSectionOpen] = useState(false);
    const [userSectionOpen, setUserSectionOpen] = useState(false);
    const [timeInputType, setTimeInputType] = useState('text');
    const [userSlotData, setUserSlotData] = useState([]);
    const [expertSlotData, setExpertSlotData] = useState([]);
    const [membersList, setMembersList] = useState([]);
    const [attendeeList, setAttendeeList] = useState([]);
    const [userSelection, setUserSelection] = useState({
        checked: true,
        date: '',
        duration: 60,
        durationDisplay: '60 mins',
        datetime: '',
        timezone: 'IST'
    });
    const timeZoneArray = [];

    useEffect(() => {
        setAccessToken(authService.getAccessToken());
        setUserSelection((selection) => {
            const updatedList = { ...selection };
            updatedList['timezone'] = moment().tz(moment.tz.guess()).format('z');
            return updatedList;
        });
    }, []);

    //useEffect for people search component
    useEffect(() => {
        let timeout;
        function updateAttendeeSelection(e) {
            if (e.detail && e.detail.length > 0) {
                let memberSelected = e.detail;
                setAttendeeList(() => {
                    const updatedMemberList = [];
                    memberSelected.forEach((select) => {
                        const newData = {
                            "attendeeEmail": select.email,
                            "attendeeHrId": select.id
                        };
                        updatedMemberList.push(newData);
                    });
                    return updatedMemberList;
                });
            }
        }
        if (invitesFieldRef && invitesFieldRef.current) {
            invitesFieldRef.current.removeFromSelectedList();
            const shadowRoot = invitesFieldRef.current.shadowRoot;
            timeout = setTimeout(() => {
                const element = shadowRoot.querySelector('.selection-list');
                if (element) {
                    element.style.overflow = 'auto';
                }
            }, 800);

            invitesFieldRef.current.addEventListener("selectedPeopleChanged", updateAttendeeSelection, false);
        }

        return () => clearTimeout(timeout);
    }, [invitesFieldRef.current]);

    useEffect(() => {
        if (caseTeamMembers?.length > 0) {
            handleRequestorName(caseTeamMembers);
        }
    }, [caseTeamMembers]);

    useEffect(() => {
        if (data.availableSlots?.length > 0) {
            data.availableSlots.forEach((avlSlot, index) => {
                const date = new Date(avlSlot.startDateTime);
                const selectedDateValue = date.toLocaleDateString();
                const slotDiff = msToTime(new Date(avlSlot.endDateTime).getTime() - new Date(avlSlot.startDateTime).getTime(), 'diff');//extractSlotDiff(avlSlot.startTime.slice(0, 5), avlSlot.endTime.slice(0, 5));

                const startTimeArray = changeTimeFormat(msToTime(new Date(avlSlot.startDateTime).getTime(), 'time'));
                const endTimeArray = changeTimeFormat(msToTime(new Date(avlSlot.endDateTime).getTime(), 'time'));

                const startTime = formatHrsMinsString(startTimeArray[0].toString()) + ':' + formatHrsMinsString(startTimeArray[2].toString());
                const endTime = formatHrsMinsString(endTimeArray[0].toString()) + ':' + formatHrsMinsString(endTimeArray[2].toString());

                setExpertSlotData((expertData) => {
                    let updatedData = [...expertData];
                    const duplicateSlotRecord = updatedData.findIndex((slot) => slot.date == selectedDateValue);
                    if (duplicateSlotRecord > -1) {
                        const oldTimeSlot = updatedData[duplicateSlotRecord].displayTimeString;
                        const newTimeSlot = slotDiff + ' (' + startTime + ' ' + startTimeArray[5] + ' - ' + endTime + ' ' + endTimeArray[5] + ')';
                        updatedData[duplicateSlotRecord].displayTimeString = oldTimeSlot + ' , ' + newTimeSlot;
                        updatedData[duplicateSlotRecord].startTime.push(avlSlot.startTime);
                        updatedData[duplicateSlotRecord].endTime.push(avlSlot.endTime);
                    } else {
                        const newSelectionDetails = {
                            date: selectedDateValue,
                            dateValue: selectedDateValue,
                            day: getDay(date),
                            userStartTime: '',
                            startTime: [avlSlot.startTime],
                            endTime: [avlSlot.endTime],
                            checked: false,
                            displayTimeString: 'Available Slots : ' + slotDiff + ' (' + startTime + ' ' + startTimeArray[5] + ' - ' + endTime + ' ' + endTimeArray[5] + ')',
                            timezone: userSelection.timezone
                        };
                        updatedData.push(newSelectionDetails);
                    }
                    return updatedData;
                });
            });
        }
    }, [data]);


    //function to extract difference between start and end time.
    const extractSlotDiff = (start, end) => {
        const startArray = start.split(':');
        const endArray = end.split(':');

        let hrDiff = Number(endArray[0]) - Number(startArray[0]);
        let minDiff = Number(endArray[1]) - Number(startArray[1]);

        console.log('calculated diff', hrDiff, minDiff);

        let timeDiffString = '';
        if (String(minDiff).includes('-')) {
            minDiff = -minDiff;
        }
        if (minDiff > 0) {
            hrDiff = hrDiff - 1;
        }
        if (hrDiff > 0) {
            timeDiffString = hrDiff + ' hours';
        }
        if (minDiff > 0) {
            timeDiffString = timeDiffString + ' ' + minDiff + ' minutes';
        }
        console.log('time Diff', timeDiffString);
        return timeDiffString;
    }

    const handleRequestorName = async (caseTeam) => {
        let resp = [];
        let arr = [];
        let requestor = '';
        for (let i = 0; i < caseTeam?.length; i++) {
            arr?.push(caseTeam[i]?.hrId);
            // if (caseTeam[i].memberType == "requestor") {
            //     requestor = caseTeam[i]?.hrId;
            // }
        }
        if (arr?.length > 0) {
            resp = await getUserProfilePictures(arr);
            setMembersList((member) => {
                let updatedList = [...member];
                if (resp?.length > 0) {
                    resp.forEach((record, index) => {
                        // if (record.id == requestor) {
                        //     console.log('requestor timezone',record.hostOfficeLocation.timezone);
                        // }
                        updatedList.push({ name: record?.lastName?.concat(', ').concat(record?.firstName), email: record.email, hrid: record.id });
                    });
                }
                return updatedList;
            });
        }
    }

    const formatHrsMinsString = (dataString) => {
        const requestString = dataString.length == 1 ? '0' + dataString : dataString;
        return requestString;
    }

    const addMinutes = (time, minsToAdd) => {
        function D(J) { return (J < 10 ? '0' : '') + J; };
        var piece = time.split(':');
        var mins = piece[0] * 60 + +piece[1] + +minsToAdd;

        return D(mins % (24 * 60) / 60 | 0) + ':' + D(mins % 60);
    };

    const getTimeInterval = (time, duration) => {
        const timeArray = time.split(':');
        const startTime = time;
        const endTime = addMinutes(time, duration);
        let timePeriod = Number(timeArray[0].trim()) > 11 ? 'PM' : "AM";
        const hh = Number(timeArray[0].trim()) > 11 ? Number(timeArray[0].trim()) - 12 : Number(timeArray[0].trim());
        const mm = timeArray[1];
        const totalMM = Number(timeArray[1].trim()) + Number(duration);

        //extract hrs from mins total
        let remainingMins = totalMM;
        let increaseHrsBy = 0;
        do {
            increaseHrsBy = remainingMins > 59 ? increaseHrsBy + 1 : increaseHrsBy;
            remainingMins = remainingMins > 59 ? remainingMins - 60 : remainingMins;
        } while (remainingMins > 59);

        const endMM = remainingMins;
        const totalHH = increaseHrsBy > 0 ? Number(timeArray[0]) + increaseHrsBy : Number(timeArray[0]);
        const endHH = Number(totalHH) > 11 ? totalHH - 12 : totalHH;
        timePeriod = totalHH > 11 ? 'PM' : "AM";

        const startHrs = formatHrsMinsString(hh.toString());
        const startMins = formatHrsMinsString(mm.toString());
        const endHrs = formatHrsMinsString(endHH.toString());
        const endMins = formatHrsMinsString(endMM.toString());

        const timeInterval = startHrs + ':' + startMins + ' - ' + endHrs + ':' + endMins + ' ' + timePeriod;

        return { timeInterval, startTime, endTime };
    }

    const getDay = (date) => {
        const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let selectedDay = weekday[date.getDay()];
        return selectedDay;
    }

    const addSlotHandler = () => {
        if (!userSelection.duration) {
            alert('Please select call duration.');
            return;
        }
        if (userSelection.date && userSelection.datetime && userSelection.timezone) {
            setUserSectionOpen(true);
            const selectedDate = new Date(userSelection.date);
            const selectedDateValue = selectedDate.toLocaleString().split(',')[0];
            setUserSlotData((userData) => {
                let updatedData = [...userData];
                const selectionData = getTimeInterval(userSelection.datetime, userSelection.duration);
                const duplicateSlotRecord = updatedData.findIndex((slot) => slot.date == selectedDateValue);
                if (duplicateSlotRecord > -1) {
                    const newTimeSlot = selectionData.timeInterval;
                    if (updatedData[duplicateSlotRecord].time.findIndex((t) => t.slot == newTimeSlot) < 0) {
                        updatedData[duplicateSlotRecord].time.push({
                            checked: true,
                            slot: newTimeSlot,
                            startTime: selectionData.startTime,
                            endTime: selectionData.endTime
                        });
                        updatedData[duplicateSlotRecord].checked = true;
                    } else {
                        toast.info('Time already added.');
                    }
                } else {
                    const newSelectionDetails = {
                        date: selectedDateValue,
                        day: getDay(selectedDate),
                        checked: true,
                        time: [{
                            checked: true,
                            slot: selectionData.timeInterval,
                            startTime: selectionData.startTime,
                            endTime: selectionData.endTime
                        }],
                        timezone: userSelection.timezone
                    };
                    updatedData.push(newSelectionDetails);
                }
                return updatedData;
            });
        } else {
            alert('Some values are missing');
        }
    }

    const handleSlotTimeSelection = (slotType, date, time) => {
        if (slotType == 'user') {
            let checkCount = 0;
            setUserSlotData((slotData) => {
                const slotUpdatedList = [...slotData];
                slotUpdatedList.forEach((slot, index) => {
                    let otherSelectedTimeIndex = -1;
                    if (slot.date == date) {
                        const timeIndex = slot.time.findIndex((t) => t.slot == time.slot);
                        if (timeIndex > -1) {
                            slot.checked = !slot.time[timeIndex].checked;
                            slot.time[timeIndex].checked = !slot.time[timeIndex].checked;
                        }
                        otherSelectedTimeIndex = slot.time.findIndex((t) => t.checked);
                    }
                    if (slot.checked || otherSelectedTimeIndex > -1) {
                        checkCount = checkCount + 1;
                    }
                });
                return slotUpdatedList;
            });

            setUserSectionOpen(checkCount > 0 ? true : false);
        } else {
            let checkedCount = 0;
            setExpertSlotData((slotData) => {
                const slotUpdatedList = [...slotData];
                slotUpdatedList.forEach((slot, index) => {
                    if (slot.date == date) {
                        slot.showTimeSelection = !slot.checked;
                        slot.checked = !slot.checked;
                    }
                    if (slot.checked) {
                        checkedCount = checkedCount + 1;
                    } else {
                        slot.userStartTime = '';
                    }
                    slot.userTimeFieldType = slot.userStartTime ? 'time' : 'text';
                });
                return slotUpdatedList;
            });
            setExpertSectionOpen(checkedCount > 0 ? true : false);
        }
    }

    const extractSelectedSlotsData = () => {
        const slotData = userSectionOpen ? userSlotData : expertSlotData;
        let selectedData = [];
        slotData.forEach((slot) => {
            if (userSectionOpen) {
                const anySlotSelected = slot.time.filter((t) => t.checked);
                if (anySlotSelected?.length > 0) {
                    anySlotSelected.forEach((us) => {
                        if (us.endTime && us.startTime) {
                            let formatedData = {
                                "availableDate": slot.date.indexOf('/') > -1 ? slot.date.replaceAll('/', '-') : slot.date,
                                "endTime": us.endTime,
                                "startTime": us.startTime,
                                "zoneId": slot.timezone
                            };
                            selectedData.push(formatedData);
                        }
                    })
                }
            } else {
                if (slot.checked) {
                    if (slot.userEndTime && slot.userStartTime) {
                        let formatedData = {
                            "availableDate": slot.dateValue,
                            "endTime": slot.userEndTime,
                            "startTime": slot.userStartTime,
                            "zoneId": slot.timezone
                        };
                        selectedData.push(formatedData);
                    }
                }
            }
        });
        return selectedData;
    }

    const handleExpertSlotSelection = (event) => {
        let isValidTime = true;
        setExpertSlotData((slotData) => {
            const slotUpdatedList = [...slotData];
            const selectedRecordIndex = slotUpdatedList.findIndex((e) => e.date == event.target.id);
            slotUpdatedList[selectedRecordIndex].userStartTime = event.target.value;
            if (event.target.value?.includes(':')) {
                let timeArray = event.target.value.split(':');
                slotUpdatedList[selectedRecordIndex].userEndTime = getCallEndDate(timeArray[0], timeArray[1]);
                isValidTime = validateSelectedCallSlot(event.target.value, slotUpdatedList[selectedRecordIndex].userEndTime, slotUpdatedList[selectedRecordIndex].startTime, slotUpdatedList[selectedRecordIndex].endTime);
                slotUpdatedList[selectedRecordIndex].error = !isValidTime;
            } else {
                slotUpdatedList[selectedRecordIndex].userEndTime = '';
                slotUpdatedList[selectedRecordIndex].error = false;
            }
            return slotUpdatedList;
        });
    }

    const validateSelectedCallSlot = (startTime, endTime, startSlots, endSlots) => {
        let flag = false;
        const startTimeValue = Number(startTime?.replace(/:/g, ''));
        const endTimeValue = Number(endTime?.replace(/:/g, ''));
        startSlots.forEach((item, index) => {
            let availTime = item.slice(0, 5);
            if (Number(availTime.replace(/:/g, '')) < startTimeValue) {
                let availEndSlot = endSlots[index]?.slice(0, 5);
                if (Number(availEndSlot.replace(/:/g, '')) > endTimeValue) {
                    flag = true;
                }
            }
        });
        return flag;
    }

    const getCallEndDate = (hours, minutes) => {
        let durationMins = 0, durationHrs = Number(hours);
        if (userSelection.duration > 59) {
            durationMins = userSelection.duration - 60;
            durationHrs = durationHrs + 1;
        } else {
            durationMins = userSelection.duration;
        }
        if (minutes > 0) {
            durationMins = durationMins + Number(minutes);
            if (durationMins > 59) {
                durationMins = durationMins - 60;
                durationHrs = durationHrs + 1;
            }
        }
        if (String(durationMins).length == 1) {
            if (durationMins == 0) {
                durationMins = String(durationMins) + '0';
            } else {
                durationMins = '0' + String(durationMins);
            }
        }

        return durationHrs + ':' + durationMins;
    }

    const sendSlotsHandler = () => {
        const attendeeData = mergedMembers();
        const slotsSelectedData = extractSelectedSlotsData();
        if (attendeeData?.length > 0 && slotsSelectedData?.length > 0) {
            setOpenModel(false);
            closePopupHandler();
            const requestData = {
                "requestId": requestId,
                "projectId": data.projectId,
                "expertProfile": {
                    "id": data.id,
                    "profileId": data.profileId,
                    "networkProvider": data.networkProvider
                },
                "followUpCall": "false",
                "reason": null,
                "availableSlots": slotsSelectedData,
                "attendees": attendeeData
            };
            dispatch(setSaveCallSlotsAPIData({ data: requestData }));
        } else {
            toast.error('Either attendee list or call slots were not selected.')
        }
    }

    const mergedMembers = () => {
        const updatedList = [...attendeeList];
        membersList.forEach((item) => {
            updatedList.push({
                "attendeeEmail": item.email,
                "attendeeHrId": item.hrid
            });
        })
        return updatedList;
    }

    const handleCaseTeamMemberRemoval = (hrid) => {
        setMembersList((member) => {
            let updatedList = [...member];
            return updatedList.filter((item) => item.hrid != hrid);
        })
    }

    return (
        <Modal isOpen={openModel} className="schedulecall__modal">
            <div className="schedulecall__wrapper model-wrapper">
                <div className="model-header">
                    <img className="close-icon" src={CrossIcon} alt="close" onClick={() => { setOpenModel(false); closePopupHandler(); }} />
                    <div className="schedulecall__heading">
                        <span>Schedule Call with {data?.expertName}</span>
                        {/* <p>Choose an available slot from the expert&lsquo;s schedule for your interview, or share your own availability to let the expert select a convenient time.</p> */}
                    </div>
                </div>
                <div className="model-body">
                    <div className="schedulecall__common-block">
                        <div className="time-timezone">
                            <div className="step-label">
                                <span className="step-count">Step 1 :</span> Select Call duration and time zone <span className="mandatory">*</span>
                            </div>
                            <div className='time-field expert-time-field right-margin'>
                                {/* <label htmlFor='duration' className="form-label">Select Duration</label> */}
                                <DropdownField data={durationList} fieldIndex='0' name='duration' fieldId='duration' placeholderTxt='Select duration'
                                    selectedValue={userSelection.durationDisplay}
                                    onSelectHandler={(selected) => {
                                        setUserSelection((selection) => {
                                            const updatedList = { ...selection };
                                            updatedList['duration'] = selected.slice(0, 2);
                                            updatedList['durationDisplay'] = selected;
                                            return updatedList;
                                        });
                                    }}
                                    onFocusOutHandler={(event) => { return true; }} />

                            </div>
                            <div className='time-field expert-time-field left-margin'>
                                {/* <label htmlFor='time' className="form-label">Select Time Zone</label> */}
                                <DropdownField data={timeZoneArray} fieldIndex='0' name='time' fieldId='time'
                                    selectedValue={userSelection.timezone}
                                    isDisabled={true}
                                    onSelectHandler={(selected) => {
                                        setUserSelection((selection) => {
                                            const updatedList = { ...selection };
                                            updatedList['timezone'] = selected;
                                            return updatedList;
                                        });
                                    }}
                                    onFocusOutHandler={(event) => { return true; }} />

                            </div>
                        </div>
                        <div className="meeting-invites">
                            <div className="step-label">
                                <span className="step-count">Step 2 :</span> Choose case team member(s) to be added  to the Invite
                            </div>
                            {invitesFieldRef &&
                                <div className='invite-input'>
                                    {/* <peopleselector /> */}
                                    <bcg-people-search app-name={CONFIG.applicationName} name='inviteInput' limit="20" width="100%" is-show-profile="false" is-multi-select="true"
                                        ref={invitesFieldRef} api-key={CONFIG.PEOPLE_API_X_API_KEY} access-token={accessToken} environment='production-internal' />
                                </div>
                            }
                            {membersList?.length > 0 && <div className="edit-caseteam">
                                {membersList.map((mem, index) =>
                                    <div className={`saved-case-team-member ${mem.duplicateValue && 'duplicate-selection'}`} key={index}><span>{mem.name}</span>&ensp;<Image src={CloseIcon} onClick={() => handleCaseTeamMemberRemoval(mem.hrid)} /></div>
                                )}
                            </div>
                            }
                        </div>
                    </div>
                    <div className="schedulecall__details">
                        <div className="expert-slots">
                            <div className="step-label slot-labels">
                                <span className="step-count">Step 3 :</span> Choose a slot from expert&lsquo;s availability
                            </div>
                            <div className={`slots-available ${userSectionOpen && 'disable-section'}`}>
                                {expertSlotData?.length > 0 &&
                                    expertSlotData.map((card, index) => (
                                        <div key={index} className={`slot-card ${index != expertSlotData.length - 1 && 'div-bottom-border'}`}>
                                            <div className="level1 slot-details">
                                                <div className="slot-date">
                                                    <Checkbox checked={card.checked} disabled={userSectionOpen} onChange={() => handleSlotTimeSelection('expert', card.date)}></Checkbox>
                                                    &nbsp;&nbsp;{card.date} &nbsp;
                                                    <div className="slot-day other-details">{'(' + card.day + ')'}</div>
                                                </div>
                                                <div className="slot-data other-details"><div className="blank-div"></div>{card.displayTimeString}</div>

                                                {card.showTimeSelection &&
                                                    <div className='start-time-expert-field'>
                                                        <label htmlFor='usertime' className="form-label">Start Time</label>
                                                        <div>
                                                            <input type={card.userTimeFieldType}
                                                                id={card.date}
                                                                name={'expertTime' + index}
                                                                placeholder='HH : MM AM/PM'
                                                                value={card.userStartTime}
                                                                className="input-fields form-control"
                                                                onFocus={() => {
                                                                    setExpertSlotData((slotData) => {
                                                                        const slotUpdatedList = [...slotData];
                                                                        const selectedRecordIndex = slotUpdatedList.findIndex((e) => e.date == card.date);
                                                                        slotUpdatedList[selectedRecordIndex].userTimeFieldType = 'time';
                                                                        return slotUpdatedList;
                                                                    })
                                                                }
                                                                }
                                                                onBlur={() => {
                                                                    setExpertSlotData((slotData) => {
                                                                        const slotUpdatedList = [...slotData];
                                                                        const selectedRecordIndex = slotUpdatedList.findIndex((e) => e.date == card.date);
                                                                        slotUpdatedList[selectedRecordIndex].userTimeFieldType = slotUpdatedList[selectedRecordIndex].userStartTime ? 'time' : 'text';
                                                                        return slotUpdatedList;
                                                                    })
                                                                }}
                                                                onChange={(event) => handleExpertSlotSelection(event)}
                                                            />
                                                            <Image className='expert-time-img' src={InputClockIcon} alt="clock icon" />
                                                        </div>
                                                        <div className="help-txt"><span>Please enter a desired start time for the provided time slots.</span></div>
                                                        {card.error && <div className="error-txt">Please select valid start time/end time.</div>}
                                                    </div>
                                                }
                                                {/* <div className="other-details"><div className="blank-div"></div>{card.timezone}</div> */}
                                            </div>
                                        </div>
                                    ))}
                            </div>

                        </div>
                        <div className="user-slots-section">
                            <div className="step-label slot-labels">
                                <span className="step-count">Step 4 :</span> If expert&lsquo;s availability is not provided or suitable for you, share your availability with networks
                            </div>
                            <div className="slots-available">
                                <div className="user-slots">
                                    <div className='time-field user-time-field right-margin'>
                                        <label htmlFor='userDate' className="form-label">Preferred Date(s)</label>
                                        <DatePickerField
                                            fieldId='userDate'
                                            minDateValue={new Date()}
                                            isDisabled={expertSectionOpen}
                                            savedValue={userSelection.date}
                                            onDateSelectHandler={(value) =>
                                                setUserSelection((selection) => {
                                                    const updatedList = { ...selection };
                                                    updatedList['date'] = value;
                                                    return updatedList;
                                                })}
                                            onCalClose={(event) => { console.log('scheduler calender closed', event); }} />
                                    </div>
                                    <div className='time-field user-time-field left-margin'>
                                        <label htmlFor='usertime' className="form-label">Preferred Time(s)</label>
                                        <div className="time-input-field">
                                            <input type={timeInputType} id='usertime' name='usertime' disabled={expertSectionOpen} placeholder='HH : MM AM/PM' value={userSelection.datetime} className="input-fields form-control"
                                                onFocus={() => setTimeInputType('time')}
                                                onBlur={() => {
                                                    if (!userSelection.datetime) {
                                                        setTimeInputType('text');
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    setUserSelection((selection) => {
                                                        const updatedList = { ...selection };
                                                        updatedList['datetime'] = event.target.value;
                                                        return updatedList;
                                                    });
                                                }}
                                            />
                                            <Image className='user-time-img' src={InputClockIcon} alt="clock icon" />
                                        </div>
                                    </div>
                                    <div className='time-field slot-button-section'>
                                        <div className="blank-block"></div>
                                        <button className="add-slot" disabled={expertSectionOpen} onClick={(e) => { e.preventDefault(); addSlotHandler(); }}>
                                            <Image src={PlusIcon} alt='plus icon' />
                                        </button>
                                    </div>
                                </div>
                                {userSlotData.length > 0 &&
                                    <div className={`${expertSectionOpen && 'disable-section'}`}>
                                        <span className="selected-slots-header">Selected Slots</span>
                                        {userSlotData.map((slot, index) => (
                                            <div key={index} className={`slot-card ${index != userSlotData.length - 1 && 'slot-border'}`}>
                                                <div className="level1 slot-details">
                                                    <div className="slot-date"> <Image src={CalIcon} alt="calender icon" />{slot.date}</div>
                                                    <div className="slot-day other-details"><div className="blank-div"></div>{slot.day}</div>
                                                    <div className="slot-time other-details">
                                                        {slot.time.map((t, i) => (
                                                            <div key={i} className="one-slot-block">
                                                                <Checkbox checked={t.checked} disabled={expertSectionOpen} onChange={() => handleSlotTimeSelection('user', slot.date, t)}></Checkbox>
                                                                <Image src={ClockIcon} alt="clock icon" />{t.slot}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {/* <div className="other-details"><div className="blank-div"></div>{card.timezone}</div> */}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="schedulecall__action-container model-footer">
                    <button className="default-button" onClick={() => { sendSlotsHandler() }}>Send Slots to Network</button>
                </div>
            </div>
        </Modal>
    );
};