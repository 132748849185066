import React, { useContext, useEffect, useRef, useState } from "react";
import './ExpertInterviewsForm.scss';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import { selectors as expertTypeSelector } from "redux/reducers/expertTypes/expertTypes_reducer";
import { selectors as requestTypeSelector } from "redux/reducers/requestType/requestType_reducer";
import { selectors as uploadFileSelector } from "redux/reducers/uploadFile/uploadFile_reducer";
import { selectors as deleteFileSelector } from "redux/reducers/deleteAttachment/deleteAttachment_reducer";
import { selectors as renameFileSelector } from "redux/reducers/renameAttachment/renameAttachment_reducer";
import { selectors as externalNetworkSelector } from "redux/reducers/externalNetwork/externalNetwork_reducer";
import { getExpertTypeAPIData } from "redux/actions/expertTypes/expertTypes.actions";
import flag from '../../assets/images/flag.svg';
import { EXPERT_INTERVIEW_FIELDS, EXPERT_SURVEY_FIELDS } from "assets/static_data/form-fields";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { FormFields } from "components/shared/FormFields";
import { FormContext } from "context/form-context";
import { getRequestTypeAPIData } from "redux/actions/requestType/requestType.actions";
import { getDeleteFileAPIData } from "redux/actions/deleteAttachment/deleteAttachment.actions";
import { getRenameFileAPIData } from "redux/actions/renameAttachment/renameAttachment.actions";
import { getExternalNetworkAPIData } from "redux/actions/externalNetwork/externalNetwork.actions";
import { uploadStart } from "redux/actions/uploadFile/uploadFile.action";
import { AlertPopup } from "components/shared/AlertPopup";

export const ExpertInterviewsForm = ({ isSurveyForm }) => {
    const requestFormRef = useRef();
    const dispatch = useDispatch();

    const expertData = useSelector(expertTypeSelector.getexpertTypesResults);
    const requestTypeData = useSelector(requestTypeSelector.getRequestTypesResults);
    const progressData = useSelector(uploadFileSelector.getFileName);
    const externalNetworkData = useSelector(externalNetworkSelector.getExternalNetworkResults);
    const deleteFileStatus = useSelector(deleteFileSelector.getDeleteFileResults);
    const renameFileStatus = useSelector(renameFileSelector.getRenameFileResults);

    const formFunctions = useContext(FormContext);
    const invalidFields = useContext(FormContext).invalidFields;

    const formFieldsData = isSurveyForm ? EXPERT_SURVEY_FIELDS : EXPERT_INTERVIEW_FIELDS;
    const [oldProvidersData, setOldProvidersData] = useState([]);
    const [attachmentData, setAttachmentData] = useState([]);
    const [uploadFileTempData, setUploadFileTempData] = useState({});
    const [uploadFolder, setUploadFolder] = useState();
    const [uploadProgressValue, setUploadProgressValue] = useState(10);
    const [uploadFileID, setUploadFileID] = useState();
    const [selectedFileRecord, setSelectedFileRecord] = useState(null);
    const [alert, setAlert] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getRequestTypeAPIData());
        dispatch(getExternalNetworkAPIData());
        dispatch(getExpertTypeAPIData());
    }, []);

    useEffect(() => {
        if (progressData && uploadFileTempData?.folderName) {
            formFunctions.toggleFormValues('attachmentsFolderName', uploadFileTempData.folderName);
            setUploadProgressValue(100);
            setAttachmentData((record) => {
                const updatedItems = [...record];
                const recordIndex = updatedItems.findIndex(file => file.fileName == progressData);
                if (recordIndex > -1) {
                    updatedItems[recordIndex].uploadProgress = 100;
                }
                return updatedItems;
            });
        }
    }, [progressData]);


    useEffect(() => {
        formFunctions.toggleDropdownData('expertType', expertData);
    }, [expertData]);
    useEffect(() => {
        if (requestTypeData?.length > 0) {
            formFunctions.toggleDropdownData('projectType', requestTypeData);
        }
    }, [requestTypeData]);
    useEffect(() => {
        if (externalNetworkData?.length > 0) {
            formFunctions.toggleDropdownData('providers', externalNetworkData);
        }
    }, [externalNetworkData]);

    useEffect(() => {
        if (uploadProgressValue < 95) {
            const min = uploadProgressValue;
            const max = 100;
            let rand = min + Math.floor(Math.random() * (max - min + 1));
            setAttachmentData((record) => {
                const updatedItems = [...record];
                const recordIndex = updatedItems.findIndex(file => file.fileName == uploadFileTempData.fileName);
                if (recordIndex > -1) {
                    if (updatedItems[recordIndex].downloadLink || updatedItems[recordIndex].uploadProgress == 100) {
                        rand = 100;
                    }
                    updatedItems[recordIndex].uploadProgress = rand;
                }
                setUploadProgressValue(rand);
                return updatedItems;
            });
        }
    }, [uploadProgressValue]);

    useEffect(() => {
        if (selectedFileRecord) {
            if (deleteFileStatus?.message && selectedFileRecord.event == 'delete') {
                setAttachmentData((record) => {
                    const updatedItems = [...record];
                    const filteredList = updatedItems.filter(item => item.fileName != selectedFileRecord.file.fileName);
                    setSelectedFileRecord(null);
                    if (!filteredList || filteredList?.length == 0) {
                        formFunctions.toggleFormValues('attachmentsFolderName', '');
                    }
                    return filteredList;
                });
            }
            if (renameFileStatus?.message && selectedFileRecord.event == 'rename' && selectedFileRecord.updatedName) {
                setAttachmentData((record) => {
                    const updatedItems = [...record];
                    const selectedFile = updatedItems.filter(item => item.fileName == selectedFileRecord.file.fileName);
                    if (selectedFile || selectedFile?.length > 0) {
                        const fileIndex = updatedItems.findIndex(ele => ele.fileName == selectedFile[0].fileName);
                        if (fileIndex > -1) {
                            const updatedName = selectedFileRecord.updatedName;
                            updatedItems[fileIndex].fileName = updatedName;
                        }
                    }
                    setSelectedFileRecord(null);
                    return updatedItems;
                });
            }
        }
    }, [deleteFileStatus, renameFileStatus, selectedFileRecord]);

    useEffect(() => {
        //handling form fields in case of editing request form.
        if (formFunctions.formView == 'edit') {
            //display uploaded files
            uploadSavedFiles();
            //error message for expired request end date
            const isEndDateExpired = (new Date(formFunctions.fieldsData.interviewEndDate) < new Date() && new Date(formFunctions.fieldsData.interviewEndDate).toDateString() != new Date().toDateString());
            const expertRecordIndex = formFieldsData.findIndex(item => item.id == "interviewEndDate");
            if (isEndDateExpired) {
                formFieldsData[expertRecordIndex].error = "Interview end date must be either today or a future date.";
                formFunctions.reCheckFieldStatus("interviewEndDate", true);
            } else {
                formFieldsData[expertRecordIndex].error = "Select interview end date.";
            }
            //setting Expert Type mandatory based on project type value
            if (formFunctions.fieldsData.projectType) {
                const expertRecordIndex = formFieldsData.findIndex(item => item.id == "expertType");
                formFieldsData[expertRecordIndex].isMandatory = formFunctions.fieldsData.projectType.includes('Diligence');
                formFieldsData[expertRecordIndex].error = formFunctions.fieldsData.projectType.includes('Diligence') ? "Please select an expert type/module." : '';
                formFunctions.reCheckFieldStatus("expertType", formFunctions.fieldsData.projectType.includes('Diligence'));
            }
        }
    }, [formFunctions.formView]);

    const updateCaseTeamMembers = (memberSelected) => {
        const fieldIndex = formFieldsData.findIndex(item => item.id === 'caseTeam');
        const record = formFieldsData[fieldIndex];
        formFunctions.toggleFormValues('caseTeam', memberSelected, record.isMandatory);
    }

    const validateErrors = (event, fieldId, isCustom) => {
        if (formFunctions.fieldsData['formNotTouched']) {
            formFunctions.toggleFormValues('formNotTouched', false);
        }
        let value;
        let invalidValue = false;
        if (isCustom) {
            value = event;
        }
        else {
            value = event?.target?.value;
        }
        if ((fieldId === 'interviewAnticipatedCount' || fieldId === 'totalSurvey') && (value < 1 || value.includes('-') || value.includes('+'))) {
            value = '';
        }
        else if (!value) {
            value = formFunctions.fieldsData[fieldId];
        }
        if (fieldId.includes("End")) {
            invalidValue = (new Date(value) < new Date() && new Date(value).toDateString() != new Date().toDateString());
        }
        const fieldIndex = formFieldsData.findIndex(item => item.id === fieldId);
        const record = formFieldsData[fieldIndex];
        if ((!value || value?.length == 0 || invalidValue) && record.error) {
            record.isValid = false;
            formFunctions.logInvalidFields(fieldId);
        } else {
            record.isValid = true;
            formFunctions.markFieldValid(fieldId);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        const fieldIndex = formFieldsData.findIndex(item => item.id === name);
        const isMandatory = formFieldsData[fieldIndex].isMandatory;
        formFunctions.toggleFormValues(name, value, isMandatory);
    };

    const handleProviderData = (data, prevData, fieldId) => {
        setOldProvidersData(prevData);
        const fieldIndex = formFieldsData.findIndex(item => item.id === fieldId);
        const isMandatory = formFieldsData[fieldIndex].isMandatory;
        formFunctions.toggleFormValues(fieldId, data, isMandatory);
    }

    const handleSelManagers = (data, field) => {
        if (data) {
            const fieldIndex = formFieldsData.findIndex(item => item.id === field);
            const isMandatory = formFieldsData[fieldIndex].isMandatory;
            formFunctions.toggleFormValues(field, data, isMandatory);
            if (field == 'projectType' || field == 'expertType') {
                const projectTypeValue = field == 'projectType' ? data : formFunctions.fieldsData['projectType'];
                const expertRecordIndex = formFieldsData.findIndex(item => item.id == "expertType");
                formFieldsData[expertRecordIndex].isMandatory = projectTypeValue.includes('Diligence');
                formFieldsData[expertRecordIndex].error = projectTypeValue.includes('Diligence') ? "Please select an expert type/module." : '';
                if ((field != 'expertType' && (!formFunctions.fieldsData['expertType'] || formFunctions.fieldsData['expertType']?.length == 0)) 
                    || (field == 'expertType' && (!data || data?.length == 0))) {
                    formFunctions.reCheckFieldStatus("expertType", projectTypeValue.includes('Diligence'));
                }
            }
            if (field.includes('EndDate')) {
                if (data < new Date() && data.toDateString() != new Date().toDateString()) {
                    const expertRecordIndex = formFieldsData.findIndex(item => item.id == field);
                    formFieldsData[expertRecordIndex].error = "Interview end date must be either today or a future date.";
                }
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // validateErrors();
    }

    const handlePhoneChange = (data, flag) => {
        if(flag){
            formFunctions.toggleFormValues('phoneNo', data);
        }
    }

    const uploadSavedFiles = () => {
        const randomFolderName = formFunctions.fieldsData?.id ? 'interview_request_' + formFunctions.fieldsData.id : randomFolderNameGenerator();
        if (!uploadFolder) {
            setUploadFolder(randomFolderName);
        }
        formFunctions.toggleFormValues('attachmentsFolderName', randomFolderName);
        const s3UploadedFiles = [];
        if (formFunctions.fieldsData?.attachmentsDetails?.length > 0) {
            formFunctions.fieldsData?.attachmentsDetails.forEach((file) => {
                let duplicateArrayEntry = s3UploadedFiles.filter(item => item.name == file.fileName);
                if (!duplicateArrayEntry || duplicateArrayEntry.length == 0) {
                    const savedFileData = {
                        id: s3UploadedFiles.length + 1,
                        name: file.fileName,
                        size: file.sizeInBytes,
                        url: file.url,
                        uploadedDate: file.uploadedDate
                    };
                    s3UploadedFiles.push(savedFileData);
                    formatAttachmentData(savedFileData, randomFolderName);
                    setUploadProgressValue(100);
                }
            });
        }
    }
    const formatAttachmentData = (data, folderName) => {
        const currentDateArray = new Date().toDateString().split(' ');
        const uploadingDate = currentDateArray[2] + ' ' + currentDateArray[1] + ', ' + currentDateArray[3];
        var sizeInMB = (data.size / (1024 * 1024)).toFixed(2);
        const fileName = data.name;
        const fileExt = fileName.slice(fileName.lastIndexOf('.') + 1, fileName.length);

        const formatedFileData = {
            fileId: data.id ? data.id : attachmentData.length + 1,
            fileName: data.name,
            fileSize: sizeInMB,
            folderName: folderName,
            sizeExceeded: false,
            fileExt: fileExt,
            uploadProgress: data.uploadedDate ? 100 : 10,
            downloadLink: data.url ? data.url : '',
            uploadDate: data.uploadedDate ? data.uploadedDate : uploadingDate
        };

        setUploadProgressValue(formatedFileData.uploadProgress);
        setUploadFileID(formatedFileData.fileId);

        let totalSize = 0;
        attachmentData.forEach((item) => {
            if (!item.sizeExceeded) {
                totalSize = totalSize + Number(item.fileSize);
            }
        });
        totalSize = totalSize + Number(sizeInMB);
        formatedFileData.sizeExceeded = totalSize > 150;
        if (totalSize > 150) {
            if (Number(sizeInMB) > 150) {
                formatedFileData.sizeError = 'The file is too large to upload.';
            } else {
                formatedFileData.sizeError = 'You have reached your upload limit. Please delete some files to continue.';
            }
        }
        if (totalSize > 150) { formatedFileData.uploadProgress = 100; }

        setUploadFileTempData(formatedFileData);

        setAttachmentData((record) => {
            const updatedItems = [...record];
            updatedItems.push(formatedFileData);
            return updatedItems;
        });

        return totalSize;
    }

    const uploadAttachment = (data) => {
        let fileData = data.target.files[0];
        if (fileData) {
            let duplicateEntry = attachmentData.filter(item => item.fileName == fileData.name);
            if (!duplicateEntry || duplicateEntry?.length == 0) {
                const randomFolderName = uploadFolder ? uploadFolder : randomFolderNameGenerator();
                if (!uploadFolder) {
                    setUploadFolder(randomFolderName);
                }
                const totalSize = formatAttachmentData(fileData, randomFolderName);

                if (totalSize <= 150) {
                    // getBase64(fileData, randomFolderName);
                    dispatch(uploadStart({ file: fileData, filename: fileData.name, folderId: randomFolderName }));
                } else {
                    setUploadProgressValue(100);
                }
            }
        }
    }

    const randomFolderNameGenerator = () => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 5) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    // const getBase64 = (file, folderName) => {   //Kirti: may required in future.
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => {
    //             dispatch(uploadStart({ file: reader.result, filename: file.name, folderId: folderName }));
    //             resolve(reader.result);
    //         };
    //         reader.onerror = (error) => reject(error);
    //     });
    // }

    const uploadFileEventsHandler = (event, file, newFileName) => {
        let updatedName = newFileName ? newFileName + '.' + file.fileExt : '';
        setSelectedFileRecord({ event, file, updatedName });
        switch (event) {
            case 'delete':
                setAlert({
                    type: 'simple',
                    message: 'Are you sure you want to delete this attachment?',
                    otherData: { fileName: file.fileName },
                    show: true
                });
                break;
            case 'rename':
                if (newFileName) {
                    let duplicateEntry = attachmentData.filter(item => item.fileName == updatedName);
                    if (duplicateEntry?.length > 0) {
                        setSelectedFileRecord({ event: event, file: file, updatedName: null });
                        toast.warn('Entered name already exists.');
                    } else {
                        dispatch(getRenameFileAPIData({ folder: uploadFolder, file: file.fileName, newFileName: updatedName }));
                    }
                }
                break;
            case 'download':
                break;
            case 'reload':
                break;
        }
    }

    const closeConfirmHandler = (confirmFlag, otherData) => {
        if (confirmFlag) {
            dispatch(getDeleteFileAPIData({ folder: uploadFolder, file: otherData.fileName }));
        }
        setAlert({});
    }

    return (
        <>
            {alert.show != undefined && alert.show != null && <AlertPopup
                open={alert.show}
                type={alert.type}
                message={alert.message}
                otherData={alert.otherData}
                closeAlertHandler={closeConfirmHandler} />}

            <div className="expertInterviewForm__wrap">
                <div className="expertInterviewForm__formwrap">
                    <form ref={requestFormRef} onSubmit={handleSubmit} className="was-vawlidated">
                        <div>
                            <FormFields fieldsData={formFieldsData} invalidFields={invalidFields} updateCaseTeamData={updateCaseTeamMembers} oldProvidersData={oldProvidersData} isSurveyForm={isSurveyForm}
                                handleFieldChange={handleChange} validateField={validateErrors} handlePhoneChange={handlePhoneChange} attachments={attachmentData} uploadProgress={uploadProgressValue} uploadFileID={uploadFileID}
                                onProvidersSelected={handleProviderData} onRscManagerSelected={handleSelManagers} uploadFileHandler={uploadAttachment} handleUploadFileEvents={uploadFileEventsHandler} />
                            <div className="divider"></div>
                            <div className="other-details width70">
                                <p>By submitting this form, you agree that you understand the potential conflicts and risks when interviewing experts:</p>
                                <ul>
                                    <li>&#8226; <span>Avoid contacting current employees of BCG&apos;s client, current employees of competitors and employees of your target firm.</span></li>
                                    <li>&#8226; <span>Never ask an expert to reveal non-public or sensitive information.</span></li>
                                </ul>

                                <div className="review-link">
                                    <img alt="uploadIcon" src={flag}></img>
                                    <a href="https://pages.navigator.bcg.com/kp/ad32eab2-c4bc-4922-9df9-c4a8c2174033" target="_blank" rel="noopener noreferrer">Review External Expert Compliance Guidelines</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
